import React from 'react'
import { Link, graphql } from 'gatsby'

import Environment from '../lib/Environment'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import CopyContainer from '../components/CopyContainer'

import './index.scss'


class Rfc extends React.Component {

  render () {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    const content = data.markdownRemark

    return (
      <Layout root={true} location={this.props.location} title={siteTitle}>
        <SEO
          title="RFC"
          keywords={[`rfc`, `specification`, `standard`]}
        />
      <CopyContainer>
        <h1>
          {content.frontmatter.title}
        </h1>
        <div className="CopyContainer-body" dangerouslySetInnerHTML={{ __html: content.html }} />
      </CopyContainer>
      </Layout>
    )
  }
}

export default Rfc

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/rfc" } }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`
